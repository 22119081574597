@import url("//hello.myfonts.net/count/34851b");

body {
    padding:0;
    margin:0;
    font-size:0;
    line-height:0;
    background:url('/img/body.jpg') repeat-x center top;
    background-color:#fff;
    min-height:100%; 
    position:relative;
    padding-bottom:139px;
}

.container {
    padding:0 15px;
}

header {
    background:#000;
    padding:11px 0;
    text-align:center;
    position:relative;

    #logo-sponsor {
        position:absolute;
        top:25px;
        left:5%;
        width:66px;
        transition: 0.15s all ease;
    }

    #logo {
        width:140px;
    }

    .wrapper {
        position:absolute;
        top:6px;
        right:15px;

        .box {
            max-width:250px;
            min-width:200px;
            display: inline-block;
            vertical-align: top;

            .title {
                font:700 16px/1.1 $font_bold;
                color:#fff;
                text-transform:uppercase;
                letter-spacing:4px;
                margin:15px 0 0 0;
            }
        }

        .club-header {
            text-align:left;

            .user {
                font:17px/1.1 $font_body;
                color:#fff;
            }

            #logout {
                color:#fff;
                font-size:13px;
                text-decoration:none;
            }
        }
    }
}

.roles-switcher {
    .switcher {
        font:17px/1.1 $font_body;
        color:#fff;

        .button {
            font: 12px/1.1 $font_bold;
        }

        &:hover {
            .switcher-items {
                max-height: 200px;
            }
        }
    }

    .switcher-items {
        max-height: 0;
        min-width: 100%;
        overflow: hidden;
        position: absolute;
        z-index: 100;
        transition: max-height .5s ease;

        .button {
            margin-top: 1px;
            margin-bottom: 1px;
            width: 100%;
        }
    }
}

nav {
    background:#252525;
    padding:10px 0;
    line-height:0;

    .menu-toggle {
        font:700 15px $font_bold;
        letter-spacing:2px;
        color:#fff;
        text-decoration:none;
        line-height:0;
        display:flex;
        align-items:center;

        img {
            margin:0 10px 0 0;
        }
    }

    .roles-switcher {
        margin-bottom: 4px;
        position: relative;

        .button {
            margin: 0;
            padding: 6px 20px;
            font: 12px/1.1 $font_bold;
            white-space: nowrap;
        }

        .switcher-items {
            max-height: none;
            display: none;
            right: 0;

            .button {
                margin-top: 2px;
                margin-bottom: 2px;
            }
        }
    }

    #logout {
        float:right;
        font:700 15px $font_bold;
        color:#fff;
        text-decoration:none;

        a {
            color:#fff;
            text-decoration:none;
        }
    }
}

.content {
    padding:15px 0;
}

.details-text {
    margin:0 0 20px 0;
}

#terms {
    background:#fff;
    font:13px/1.2 $font_body;
    padding:15px;
    margin:0 0 20px 0;
    overflow:auto;
    height:150px;
    color:#000;
}

.text {
    font:14px/1.3 $font_body;
    color:#fff;
}

label {
    font:14px/1 $font_primary;
    display:block;
    margin:0 0 10px 0;
}

.form-control {
    background:#555;
    padding:5px;
    border:0;
    font:13px/1 $font_primary;
    display:block;
    width:100%;
    color:#fff;
    margin:0 0 10px 0;
    outline:none;
    resize:none;
}

textarea.form-control {
    line-height:1.2;
}

.button {
    background:#000;
    border:1px solid #fff;
    color:#fff;
    text-decoration:none;
    display:inline-block;
    padding:7px 25px;
    text-transform:uppercase;
    font: 15px/1.1 $font_bold;
    margin:10px 0;
    -webkit-transition: background 0.3s linear;
    -moz-transition: background 0.3s linear;
    -ms-transition: background 0.3s linear;
    -o-transition: background 0.3s linear;
    transition: background 0.3s linear;

    span {
        font-family:$font_bold;
    }

    &:hover {
        background:#8cc63f;
    }

    &-remove {
        background: #e9351c !important;

        &:hover {
            background: darken(#e9351c, 15%)!important;
        }
    }
}

.alert {
    padding: 15px 10px;
}


footer {
    background:#000;
    text-align:center;
    color:#fff;
    padding:15px;
    font:14px/1.2 $font_body;
    border-top:2px solid #fff;
    position:absolute;
    left:0;
    right:0;
    bottom:0;
    height:99px;
}

.unactive {
    -webkit-filter: grayscale(1);
    -ms-filter: grayscale(1);
    filter: grayscale(1);
}

#nemeziz-messi {
    background:url('/img/banners/redefine_touch.jpg') no-repeat center #fff;
    min-height:292px;
    padding:65px 38px 0;

    .text {
        font:25px/1 $font_body;
        color:#fff;

        strong {
            font-family:$font_bold;
            font-weight:700;
        }
    }
    
    .button {
        display:inline-block;
        font:16px/1 $font_body;
    }

    a.full {
        display:block;
        width:100%;
        height:100%;
        position:absolute;
        top:0;
        left:0;
    }
}