$brand_primary:#2b4ca8;
$brand_secondary:#ce1d25;
$brand_tertiary:#161f37;

$font_primary:'FuturaBTWXX-MediumItalic';
$font_body:'FuturaBTWXX-BookItalic';
$font_bold:'FuturaBTWXX-BoldItalic';

$notification_success:#70c01a;
$notification_info:#1c62a9;
$notification_warning:#e02b2b;

@font-face {font-family: 'FuturaBTWXX-BookItalic';src: url('/fonts/34851B_0_0.eot');src: url('/fonts/34851B_0_0.eot?#iefix') format('embedded-opentype'),url('/fonts/34851B_0_0.woff2') format('woff2'),url('/fonts/34851B_0_0.woff') format('woff'),url('/fonts/34851B_0_0.ttf') format('truetype');}
@font-face {font-family: 'FuturaBTWXX-MediumItalic';src: url('/fonts/34851B_1_0.eot');src: url('/fonts/34851B_1_0.eot?#iefix') format('embedded-opentype'),url('/fonts/34851B_1_0.woff2') format('woff2'),url('/fonts/34851B_1_0.woff') format('woff'),url('/fonts/34851B_1_0.ttf') format('truetype');}  
@font-face {font-family: 'FuturaBTWXX-BoldItalic';src: url('/fonts/34851B_2_0.eot');src: url('/fonts/34851B_2_0.eot?#iefix') format('embedded-opentype'),url('/fonts/34851B_2_0.woff2') format('woff2'),url('/fonts/34851B_2_0.woff') format('woff'),url('/fonts/34851B_2_0.ttf') format('truetype');}  