.modal-box {
  display: none;
  position: absolute;
  z-index: 1000;
  width: 98%;
  font: 15px/1.3 $font_body;
  line-height: normal;
  background: white;
  border-bottom: 1px solid #aaa;
  border-radius: 4px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-clip: padding-box;

  header {
    background: none;
  }

  header,
  .modal-header {
    padding: 1.25em 1.5em;
    border-bottom: 1px solid #ddd;
  }

  .modal-body { padding: 2em 1.5em; }

  footer,
  .modal-footer {
    padding: 1em;
    border-top: 1px solid #ddd;
    background: rgba(0, 0, 0, 0.02);
    text-align: right;
  }
}

.modal-overlay {
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 900;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3) !important;
}

a.close {
  line-height: 1;
  font-size: 1.5em;
  position: absolute;
  top: 12%;
  right: 2%;
  text-decoration: none;
  color: #bbb;


  &:hover {
    color: #222;
    -webkit-transition: color 1s ease;
    -moz-transition: color 1s ease;
    transition: color 1s ease;
  }
}

@media (min-width: 32em) {
  .modal-box { width: 70%; }
}