.dashboard {

    .dashboard-item {
        min-height:300px;
        position:relative;
        margin:0 0 15px 0;

        &.session-plans {
            background:url('/img/dashboard/session-plans.jpg') no-repeat top center #000;
        }

        &.resources {
            background:url('/img/dashboard/resources.jpg') no-repeat top center #000;
        }

        &.calendar {
            background:url('/img/dashboard/calendar.jpg') no-repeat right 96px #000;
        }

        &.account {
            background:url('/img/dashboard/account.jpg') no-repeat top right #000;
        }

        .icon {
            background:#fff;
            padding:15px;
            text-align:center;

            .dashboard-icon {
                max-width:60px;
            }
        }

        .text {
            padding:15px;
            width:100%;
            text-align:center;
            position:absolute;
            bottom:0;
            left:0;

            .dashboard-title {
                font:25px/1.1 $font_body;
                text-transform:uppercase;
            }

            .dashboard-sub-title {
                font:27px/1.1 $font_bold;
                text-transform:uppercase;
            }
        }
    }

    &.player-parent {
        .dashboard-item {
            &.session-plans {
                background:url('/img/dashboard/player-parent-session-plans.jpg') no-repeat top center #000;
            }

            &.resources {
                background:url('/img/dashboard/player-parent-resources.jpg') no-repeat top center #000;
            }

            &.calendar {
                background:url('/img/dashboard/player-parent-calendar.jpg') no-repeat top center #000;
            }

            &.account {
                background:url('/img/dashboard/player-parent-account.jpg') no-repeat top right #000;
            }
        }
    }
}

#modules {

    .icon {
        background:#fff;
        padding:15px;
        text-align:center;

        img {
            max-width:60px;
        }
    }

    a {
        text-decoration:none;
    }

    .module {
        background:url('/img/module-bg.jpg') repeat-x top right #000;
        min-height:148px;
        margin:0 0 15px 0;
        text-align:center;
        padding:15px;

        .module-name {
            font:700 23px/1 $font_bold;
            color:#fff;
            text-transform:uppercase;
            margin-bottom:5px;
        }

        .module-dates {
            font:23px/1 $font_body;
            color:#fff;
            text-transform:uppercase;
        }
    }
}

#module-types {
    .main-title {
        background:#000;
        color:#fff;
        padding:25px;
        font:20px/1 $font_body;
        text-align:center;
        text-transform:uppercase;
        margin:0 0 15px 0;

        span {
            font-family:$font_bold;
        }
    }

    .module-type-link {
        color:#fff;
        text-decoration:none;
    }

    .module-type {
        background:url('/img/module-type-bg.jpg') no-repeat top center #000;
        padding:30px;
        text-align:center;
        margin:0 0 15px 0;

        img {
            max-width:50%;
            margin:0 0 20px 0;
        }

        .module-type-name {
            color:#86c03f;
            font:700 20px/1 $font_bold;
            text-transform:uppercase;
            text-decoration:none;
            margin:5px 0;
        }

        .module-type-weeks {
            color:#fff;
            font:700 19px/1 $font_bold;
            text-transform:uppercase;
            text-decoration:none;
        }
    }
}

.empty {
    background:#000;
    padding:15px;
    text-align:center;
    font:23px/1 $font_body;
    color:#fff;
    margin:0 0 15px 0;

    strong {
        font-weight:700;
    }

    i {
        color:#c70000;
    }

    a {
        color:#4e913f;
        text-decoration:none;
        font-size:16px;
        margin:10px 0 0 0;
    }
}