#session-plan {
    .plan-border {
        position:relative;
        background:#292929;
        margin:0 0 20px 0;

        #module-icon {
            position:absolute;
            z-index:20;
            top:-30px;
            left:-70px;
            width:130px;
        }
    }

    .plan-title {
        background:#292929;
        padding:20px 10px;
        font:16px/1.4 $font_body;
        color:#fff;

        strong {
            color:#8cc63f;
        }
    }
    
    .plan-image {
        background:#000;
        text-align:center;
        padding:10px;
        
        img {
            max-width:100%;
        }
    }
    
    #tabs {
        padding:15px;

        .tab-navigation {
            padding:0;
            margin:0;
            
            li {
                width:100%;
                display:block;
                outline:none;

                a {
                    display:block;
                    background:#000;
                    font:700 15px/1 $font_bold;
                    color:#fff;
                    text-transform:uppercase;
                    text-decoration:none;
                    border:1px solid #fff;
                    padding:10px 15px;
                    outline:none;

                    &:hover, &:active, &:visited {
                        outline:none;
                    }
                }

                &.active {
                    a {
                        border:1px solid #000;
                    }
                }
            }
        }

        #tab-content {
            .tab-content {
                background:#000;
                padding:15px;
                font:15px/1.3 $font_body;
                color:#fff;

                .title {
                    font:700 17px/1 $font_bold;
                    margin:0 0 5px 0;
                }

                ul, ol {
                    margin:10px 0 10px 20px;
                }

                ol {
                    list-style-type: none;
                    margin-left: 0;
                }

                ol > li {
                    counter-increment: customcounter;
                }

                ol > li:before {
                    margin-left: -35px;
                    width: 35px;
                    content: "#" counter(customcounter) " - ";
                    float: left;
                }
                ol > li {
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 35px;
                }

                ol:first-child {
                    counter-reset: customcounter;
                }
            }

            .tab-desktop-content {
                display:none;

                &.active {
                    display:block;
                }
            }
        }
    }

    #session-video {
        border-bottom:8px solid #fff;
        border-top:8px solid #fff;
        background:#292929;
    }

    .step-circle-title {
        font:15px/1.2 $font_body;
        color:#fff;
        margin:15px 0 0 0;

        span {
            color:#8cc63f;
        }
    }

    #tab-slider {
        font:14px/1.3 $font_body;
        color:#fff;
        padding:15px;
    }

    .tab-title {
        font:700 17px/1 $font_bold;
        text-transform:uppercase;
        color:#fff;
        margin:0 0 5px 0;
    }

    .slider-content {
        ul, ol {
            margin:0 0 0 20px;
        }

        ol {
            list-style-type: none;
            margin-left: 0;
        }

        ol > li {
            counter-increment: customcounter;
        }

        ol > li:before {
            margin-left: -35px;
            width: 35px;
            content: "#" counter(customcounter) " - ";
            float: left;
        }
        ol > li {
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 35px;
        }

        ol:first-child {
            counter-reset: customcounter;
        }
    }
    
    #session-nav {
        padding:10px 10px 120px 10px;
        position:relative;
        background:#292929;

        #buttons {
            padding:15px 0 0 0;
        }

        .session-nav-button {
            background:#000;
            padding:10px;
            border-radius:5px;
            display:block;
            margin:0 0 20px 0;
            text-decoration:none;
            color:#fff;
            height:90px;
            padding:25px 15px 0 73px;

            .button {
                margin:0;
                display:block;
                text-align:center;
                padding:18px 0;
            }

            &.your-plan {
                background:url('/img/icon-your-plan.png') no-repeat 23px center #000;
            }

            &.session-review {
                background:url('/img/icon-session-review.png') no-repeat 20px center #000;
                padding-top:24px;
            }

            &.download-pdf {
                background:url('/img/icon-download-pdf.png') no-repeat 27px center #000;
                padding-top:28px;
            }

            &:hover {
                background-color:#343434;

                div {
                    background:#8cc63f;
                }
            }
        }

        #steps {
            width:100%;
            margin:20px 0 0 0;

            #up-next {
                background:#000;
                color:#fff;
                height:60px;
                font:14px/1.3 $font_body;
                text-align:center;
                display:flex;
                align-items:center;
                justify-content:center;
                padding:0 5px;

                strong {
                    font-family:$font_bold;
                    font-weight:700;
                    text-transform:uppercase;
                }
            }

            .step-button {
                background:#8cc63f;
                color:#fff;
                height:60px;
                display:block;
                font-size:25px;
                display:flex;
                align-items:center;
                justify-content:center;
                text-decoration:none;

                &.disabled {
                    cursor:not-allowed;
                    background:#d8ebc0;
                }
            }
        }
    }

    #next-session {
        padding: 12px 0;

        img {
            margin-bottom: 4px;
        }

        .btn {
            margin-top: 4px;
            border: 1px #fff solid;
            padding: 8px 4px;
            font: 14px/1 $font_body;
            color: #fff;
            letter-spacing: 4px;
            text-decoration: none;
            text-transform: uppercase;
            -webkit-transition: color 1s, background-color 1s;
            -moz-transition: color 1s, background-color 1s;
            -ms-transition: color 1s, background-color 1s;
            -o-transition: color 1s, background-color 1s;
            transition: color 1s, background-color 1s;
            display: block;

            &:hover {
                color: #000;
                background-color: #fff;
            }
        }

        .thumbnail {
            position: relative;

            .next-session-video {
                position: absolute;
                width: 100%;
                height: 100%;
                background: url('/img/next-session-video.png') center no-repeat;
            }
        }
    }

    #swiper {
        font:14px/1 $font_body;
        color:#fff;
        text-align:center;
        margin:0 0 20px 0;
    }

    &.player-parent {
        #session-video {
            border: 0;
        }

        #session-nav {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;
            background-image: none;
        }

        .plan-border {
            #module-icon {
                top: -20px;
                left: 12px;
            }
        }

        .plan-title {
            padding-left: 10px;
            text-transform: uppercase;
        }
    }
}