/**
 * Below XS Down, iphone 5
 */
@media (max-width: 320px) {

}

/**
 * Below XS Down, Iphone 6
*/
@media (max-width: 375px) {

}

/**
 * XS Down
 */
@media (max-width: 575px) {

}

/**
 * SM Up
 */
@media (min-width: 544px) {
    header {
        #logo-sponsor {
            width:100px;
        }
    }
}

/**
 * SM Down
 */
@media (max-width: 767px) {
    .section-sep {
        border-bottom: 8px white solid;
    }
}

/**
 * SM Up
 */
@media (min-width: 768px) {
    header {
        #logo-sponsor {
            width:145px;
            top:12px;
        }
    }

    #session-plan {
        .plan-border {
            margin-left: -8px;
            margin-right: -8px;
            border:8px solid #fff;
        }

        #tabs {
            min-height:305px;

            .tab-navigation {

                li {
                    display:inline-block;
                    margin:0 5px 0 0;

                    a {
                        padding:10px 15px;
                    }
                }
            }
        }

        #session-video {
            border-top:0;
        }

        #session-nav {
            padding:15px 15px 11px 15px;
            min-height:330px;

            #buttons {
                padding:25px 0 0 0;
            }
        }

        .border-right {
            border-right:4px solid #fff;
        }

        .border-left {
            border-left:4px solid #fff;
        }

        .border-top {
            border-top:4px solid #fff;
        }

        .border-bottom {
            border-bottom:4px solid #fff;
        }

        &.player-parent {
            .plan-title {
                padding-left: 150px;
            }
        }
    }
}

/**
 * LG Up
 */
@media (min-width: 992px) {

    body {
        padding-bottom:85px;
    }

    .content {
        padding:30px;
    }

    .login {
        background:url('/img/login.jpg') no-repeat top left #000;
        min-height:335px;

        &.details {
            background:#000!important;
        }

        .login-title {
            margin:20px 0;
        }
    }

    .dashboard {
        .dashboard-item {
            min-height:339px;
            margin:0 0 30px 0;

            &.session-plans {
                background:url('/img/dashboard/session-plans-desktop.jpg') no-repeat top left;
            }

            &.resources {
                background:url('/img/dashboard/resources-desktop.jpg') no-repeat top left;
            }

            &.calendar {
                background:url('/img/dashboard/calendar-desktop.jpg') no-repeat top left;
            }

            &.account {
                background:url('/img/dashboard/account-desktop.jpg') no-repeat top left;
            }

            .text {
                .dashboard-title, .dashboard-sub-title {
                    font-size:21px;
                }
            }
        }

        &.player-parent {
            .dashboard-item {
                &.session-plans {
                    background:url('/img/dashboard/player-parent-session-plans-desktop.jpg') no-repeat top left;
                }

                &.resources {
                    background:url('/img/dashboard/player-parent-resources-desktop.jpg') no-repeat top left;
                }

                &.calendar {
                    background:url('/img/dashboard/player-parent-calendar-desktop.jpg') no-repeat top left;
                }

                &.account {
                    background:url('/img/dashboard/player-parent-account-desktop.jpg') no-repeat top left;
                }
            }
        }
    }
    
    #modules {
        .module {
            background:url('/img/module-bg-desktop.jpg') no-repeat top left #000;
            text-align:left;
            padding:50px 0 0 180px;

            .module-name {
                font:700 19px/1 $font_bold;
                margin-bottom:5px;
            }

            .module-dates {
                font:19px/1 $font_body;
            }

            &.player-parent {
                background-image: url('/img/player-parent-module-bg-desktop.jpg');
            }
        }
    }

    #module-types {
        .module-type {
            max-height:322px;
        }
    }

    footer {
        text-align:left;
        border:0;
        height:55px;

        #social-icons {
            margin:0;

            img {
                margin:0 1px;
            }
        }

        #footer-copyright {
            font-size:12px;
            line-height:1.8;

            img {
                position:relative;
                top:6px;
            }
        }

        #footer-sponsors {
            padding-right:15px;
        }
    }

    #session-plan {
        .plan-title {
            padding-left:70px;
        }

        #session-video {
            min-height:330px;
        }
        
        #session-nav {
            background:url('/img/session-plan-splitter.jpg') repeat-y top center;

            .session-nav-button {
                .button {
                    font-size:12px;
                }
            }
        }

        #tabs {
            .tab-navigation li {
                width:auto;
                margin-right:3px;

                a {
                    display:inline-block;
                    font-size:12px;
                    padding:10px 12px;
                }
            }
        }
    }
}

/**
 * LG down
 */
@media (max-width: 992px) {
    footer {
        #social-icons {
            margin:10px 0 0 0;

            img {
                margin:0 3px;
            }
        }
    }
}

/**
 * XL Down
 */
@media (max-width: 1199px) {

}

/**
 * XL Up
 */
@media (min-width: 1200px) {
    .container {
        width:1185px;
    }

    #modules {
        .module {
            .module-name {
                font:700 25px/1 $font_bold;
            }

            .module-dates {
                font:23px/1 $font_body;
            }
        }
    }

    .dashboard {
        .dashboard-item {
            .text {
                .dashboard-title, .dashboard-sub-title {
                    font-size:23px;
                }
            }
        }
    }

    #session-plan {
        #session-nav {
            .session-nav-button {
                .button {
                    font-size:14px;
                }
            }
        }
    }
    
    footer {
        #social-icons {
            img {
                margin:0 3px;
            }
        }

        #footer-copyright {
            font-size:14px;

            img {
                position:relative;
                top:6px;
            }
        }
    }
}