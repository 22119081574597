.login {
    background:#000;
    color:#fff;
    padding:30px;
    border:1px solid #555;

    .errors {
        color:#dc0b0b;
        font:700 13px/1 $font_primary;
        margin:0 0 20px 0;
    }

    .icon {
        background:#fff;
        margin:-30px -30px 30px -30px;
        padding:15px;
        text-align:center;

        img {
            width:70px;
        }
    }

    .login-title {
        font:700 17px/1 $font_bold;
        text-transform:uppercase;
        letter-spacing:2px;
        margin:0 0 20px 0;
    }

    .sub-title {
        margin-bottom: 10px;
        font:13px/1 $font_primary;
        line-height: 16px;
    }

    button {
        border:2px solid #fff;
        background:none;
        padding:8px;
        font:700 13px/1 $font_primary;
        letter-spacing:2px;
        color:#fff;
        display:block;
        width:100%;

        &:hover {
            background:#8cc63f;
        }
    }

    #align-bottom {
        vertical-align:bottom;
    }

    #forgot-pass {
        color:#fff;
        font:13px/1 $font_primary;
        position:absolute;
        bottom:5px;
        right:15px;
    }
}