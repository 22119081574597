.text-right {
    text-align:right;
}

.text-center {
    text-align:center;
}

.pull-right {
    float:right;
}

.img-responsive {
    max-width:100%;
    width:100%;
}

.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
    display: none !important;
}

@media (min-width: 1200px) {
    .visible-lg {
        display: block !important;
    }
}

@media (max-width: 1199px) and (min-width: 992px) {
    .visible-md {
        display: block !important;
    }
}

@media (max-width: 991px) and (min-width: 768px) {
    .visible-sm {
        display: block !important;
    }
}

@media (max-width: 767px) {
    .visible-xs {
        display: block !important;
    }

    .hidden-xs {
        display: none !important;
    }
}

button, input[type=submit] {
    transition: background-color 0.3s linear;
}

.success {
    color:#8cc63f;
    font:700 15px/1 $font_bold;
    margin:0 0 20px 0;
}

@media only screen and (min-width : 768px) {
    .is-table-row {
        display: table;
    }
    
    .is-table-row [class*="col-"] {
        float: none;
        display: table-cell;
        vertical-align: top;
    }
}