/** VARIABLES **/
$background-color: #fff;
$secondary-color: #86c03f;
$item-background-color: #000;
$item-icon-size: 46px;

.content {
    margin-bottom: 30px;
    line-height: normal;

    .wrapper {
        padding: 7px;
        background-color: #fff;
    }

    .panel {
        font: 15px/1.3 $font_body;

        .panel-heading {
            padding: 10px 10px;
            font-size: 16px/1.4;
            color: #fff;
            text-transform: uppercase;
            background-color: #292929;
            position: relative;

            strong {
                line-height: 40px;
            }

            .icon {
                margin: 0 12px;
                width: 67px;
                height: 74px;
                background-image: url('/img/panel-heading-icon.png');
                display: inline-block;
                vertical-align: middle;

                span {
                    width: 100%;
                    height: 100%;
                    background-position: center;
                    background-repeat: no-repeat;
                    display: block;
                }

                .icon-pdf {
                    background-image: url('/img/panel-heading-icon-pdf.png');
                }

                .icon-video {
                    background-image: url('/img/panel-heading-icon-video.png');
                }
            }
        }

        .panel-body {
            margin-top: 5px;
            margin-bottom: 5px;

            .slider {
                display: flex;
                flex-direction: row;
                align-items: stretch;

                .slider-navigation-button {
                    width: 50px;
                    font-size: 43px;
                    color: #fff;
                    background-color: $secondary-color;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    text-decoration: none;

                    &:hover {
                        background-color: darken($secondary-color, 10%);
                    }
                }

                .slider-body {
                    margin-left: 6px;
                    margin-right: 6px;
                    flex-grow: 1;
                    display: flex;
                    flex-direction: row;
                    overflow: hidden;

                    .item {
                        flex: 0 0 25%;
                        display: flex;
                        flex-direction: column;
                    }

                    .item-content {
                        margin-left: 4px;
                        margin-right: 4px;
                        height: 100%;
                        color: #fff;
                        white-space: normal;
                        background-color: $item-background-color;
                        display: flex;
                        flex-direction: column;

                        &.item-border {
                            border: 1px $item-background-color solid;
                        }

                        .icon {
                            width: $item-icon-size;
                            font-size: 25px;
                            text-align: center;
                            line-height: $item-icon-size;
                            background-color: $secondary-color;
                            position: absolute;
                            top: 0;
                            right: 0;
                        }
                    }

                    .item-img-wrapper {
                        margin-bottom: 20px;
                        height: 178px;
                        position: relative;

                        &.icon-overlap {
                            font-size: 120px;
                            background-color: $secondary-color;
                        }

                        img {
                            margin: auto;
                            width: 100%;
                            height: 100%;
                            display: block;
                        }
                    }

                    .item-title {
                        font-weight: bold;
                        text-transform: uppercase;
                    }

                    .item-body {
                        padding: 5px 0;
                        flex-grow: 1;
                        p {
                            margin: 0;
                        }
                    }

                    .item-footer {
                        padding: 20px 0;
                    }

                    .btn {
                        border: 1px #fff solid;
                        padding: 4px 25px;
                        color: #fff;
                        letter-spacing: 4px;
                        text-decoration: none;
                        text-transform: uppercase;
                        -webkit-transition: color 1s, background-color 1s;
                        -moz-transition: color 1s, background-color 1s;
                        -ms-transition: color 1s, background-color 1s;
                        -o-transition: color 1s, background-color 1s;
                        transition: color 1s, background-color 1s;

                        &:hover {
                            color: #000;
                            background-color: #fff;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 575px) {
    .content .panel .panel-body .slider .slider-body .item {
        flex: 0 0 100%;
    }
}
@media (min-width: 576px) and (max-width: 767px) {
    .content .panel .panel-body .slider .slider-body .item {
        flex: 0 0 50%;
    }
}
@media (min-width: 768px) and (max-width: 992px) {
    .content .panel .panel-body .slider .slider-body .item {
        flex: 0 0 33.3%;
    }
}